import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import { Link } from "gatsby";

const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="Merger of iAMPS & Hz AI-EMC Design Synthetics BU"
        paragraph="Elfonze Technologies Announces Strategic Merger of iAMPS & Hz’s Business Unit focussed on AI-EMC Design Synthetics into its portfolio."
      />
      <h1 className="d-none">Elfonze Technologies Announces Strategic Merger of iAMPS & Hz’s Business Unit focussed on AI-EMC Design Synthetics into its portfolio.</h1>
      <section className="blog-pg single section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="post">
                <div className="img">
                  <img
                    src="/img/elf_insider/elfonze_iAmps_merger.jpeg"
                    alt="Elfonze_iAmps_Collaboration"
                  />
                </div>
                <div className="img">
                  <img
                    src="/img/elf_insider/elfonze_iAmps_stats.png"
                    alt="Elfonze_iAmps_Collaboration"
                  />
                </div>
                <div className="content pt-60">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="cont blog_h2">
                        <h2>- The Merger</h2>
                        <p>
                          Bengaluru, KA — Elfonze Technologies, a pioneer in
                          Enterprise, Digital and Connected Supply Chain
                          Services is excited to announce the successful merger
                          of iAMPS & Hz’s business unit AI-EMC Design Synthetics
                          into our company as part of our ongoing efforts to
                          enhance and expand our service offerings. This merger
                          is effective as of 6th May 2024, marking a significant
                          milestone in our commitment to innovation and
                          excellence in Electronics System Design and
                          Manufacturing (ESDM) domain.
                        </p>
                        {/* <h2>- Exploring the Possibilities </h2> */}
                        <p>
                          iAMPS & Hz is renowned in the field of Electromagnetic
                          Compatibility, offering specialized services in
                          design, simulation, verification, validation,
                          certification and calibration. Known for pioneering
                          approaches, the company delivers impactful solutions
                          in lab commissioning, EMC sustainability and ensures
                          robust cybersecurity for embedded systems.
                        </p>
                        {/* <h2>- The Role of Elfonze Technologies  </h2> */}
                        <p>
                          We are profoundly grateful to Mr. Sanjeev Gupta, CEO
                          of the Karnataka Digital Economy Mission (KDEM), for
                          his forward-thinking approach, which was crucial in
                          spearheading this transformative merger. His ability
                          to inspire and drive this collaboration has been
                          instrumental in paving the way for a ground-breaking
                          future for Elfonze. Thank you, Mr. Sanjeev, for
                          planting the seeds of this significant initiative.
                        </p>
                        {/* <h2>- Benefits of Connected Supply Chains  </h2> */}
                        <p>
                          The strategic merger between Elfonze Technologies and
                          iAMPS & Hz has led to the creation of the SynthEMC AI
                          Platform™, a pioneering fusion of AI and EMC
                          technologies that sets new industry standards in
                          electromagnetic compatibility design. This development
                          significantly enhances our technological capabilities,
                          placing us at the forefront of innovation and our
                          expanded reach now covers a broad spectrum of sectors,
                          including telecommunications, medical technologies,
                          aerospace, defence, renewable energy, and automotive
                          industries, allowing us to serve a wider array of
                          global clients. Empowered by our intensified
                          commitment to research and development, we are
                          dedicated to delivering customized, cutting-edge
                          solutions that meet the dynamic needs of our
                          customers.
                        </p>

                        <h2 className="mb-20"> - Key Add-On Credentials: </h2>
                        <div className="blog_h2 custom_sublist">
                        <h2> - Innovation & Leadership:</h2>
                        <ul className="smp-list ab--left-align custom_sublist">
                          <li>
                            <p className="no-margin">
                              121 domestic and 52 international
                              certifications
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">
                              32 patents
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">
                              61 advanced labs (including ALSE
                              shielded and RF chambers)
                            </p>
                          </li>
                        </ul>

                        <h2> - Design Wins:</h2>
                        <ul className="smp-list ab--left-align custom_sublist">
                          <li>
                            <p className="no-margin">Power Electronics – 12</p>
                          </li>
                          <li>
                            <p className="no-margin">IOT Devices – 15+</p>
                          </li>
                          <li>
                            <p className="no-margin">
                              Industrial Embedded – 20+
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">
                              Consumer Electronics – 18+
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">Medical Devices – 7</p>
                          </li>
                          <li>
                            <p className="no-margin">Automotive – 17+</p>
                          </li>
                          <li>
                            <p className="no-margin">Defence – 8+</p>
                          </li>
                        </ul>

                        <h2> - Certifications & Accreditations</h2>
                        <ul className="smp-list ab--left-align custom_sublist">
                          <li>
                            <p className="no-margin">
                              47 internal and 12 external certifications
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">
                              10/22 process and 32/55 product certifications
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">8/10 accreditations</p>
                          </li>
                        </ul>

                        <h2> - Efficiency & Impact:</h2>
                        <ul className="smp-list ab--left-align custom_sublist">
                          <li>
                            <p className="no-margin">
                              30% reduction in testing costs and 40% fewer
                              re-tests
                            </p>
                          </li>
                        </ul>

                        <h2> - Global Reach & Expertise:</h2>
                        <ul className="smp-list ab--left-align custom_sublist">
                          <li>
                            <p className="no-margin">
                              272 satisfied customers and 173 products tested
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">
                              638 live webinars delivered and 3,200 hours of
                              instructional content
                            </p>
                          </li>
                        </ul>
                        </div>


                        <h2 className="mb-20"> - Future Outlook</h2>
                        <div className="blog_h2 custom_sublist">
                        <ul className="smp-list ab--left-align custom_sublist">
                          <p className="no-margin">
                            As we forge ahead, Elfonze Technologies remains
                            steadfast in its commitment to driving growth and
                            fostering innovation. We aspire to be the premier
                            Engineering and Technology Partner for leading
                            national and international OEMs.
                          </p>
                          <li>
                            <p className="no-margin">
                              <strong>SynthEMC AI Platform</strong> - Alpha
                              version in September, Beta in December.
                            </p>
                          </li>
                          <li>
                            <p className="no-margin">
                              <strong>Target Market Expansion</strong> - Design
                              Companies: Engage MSMEs - 350 Startups - 2,400,
                              Tier 1 and Tier 2 corporations – 230
                            </p>
                          </li>

                          <li>
                            <p className="no-margin">
                              <strong>Onboarding</strong> - Over 110 simulation
                              companies joining the platform.
                            </p>
                          </li>
                          <li>
                            <p className="no-margin"><strong>Service Expansion -</strong></p>
                          </li>
                          <ul className="smp-list ab--left-align custom_sublist">
                              <li>
                                <p className="no-margin">
                                  <strong>Testing Labs</strong> - Collaborating with
                                  2,700+ EMC, RF, Safety, and
                                  Environmental/Reliability labs.
                                </p>
                              </li>
                              <li>
                                <p className="no-margin">
                                  <strong>Certification and Engagement</strong> -
                                  Customer Certifications: Targeting engagement with
                                  over 3,000 customers.
                                </p>
                              </li>
                              <li>
                                <p className="no-margin">
                                  <strong>Infrastructure Enhancement</strong> -
                                  State-of-the-Art Facilities: Developing
                                  cutting-edge in-house testing, inspection, and
                                  calibration labs.
                                </p>
                              </li>
                            </ul>
                        </ul>

                        <h2 className="mt-30 mb-30">Quote from Leadership:</h2>
                        <p className="no-margin mt-30">
                          “I am very happy to know about this merger, their
                          collective vision to design innovative product and
                          services will support ESDM companies to go global.
                          With their special focus on start ups, this comes at
                          right time and right place - in Karnataka the hub of
                          Digital Economy of India. Many congratulations to
                          Vijai and Ananth. KDEM will always be there to support
                          Elfonze to grow more.”
                        </p>
                        <h6 className="quote-author">
                          Mr. Sanjeev Gupta, CEO of Karnataka Digital Economy
                          Vision
                        </h6>

                        <br />
                        <br />
                        <p className="no-margin mt-30">
                          “Innovation is not about enhancing what is, but
                          envisioning what could be. As the EMC landscape
                          evolves, standing still is akin to moving backwards.
                          This merger is not just a convergence of capabilities;
                          it's a fusion of future visions. We at iAMPS & Hz are
                          thrilled to join forces with Elfonze Technologies, as
                          we continue to redefine the boundaries of what's
                          possible in technology."
                        </p>
                        <h6 className="quote-author">
                          Dr. Ananth Krishna, CEO &Founder of iAMPS & Hz
                        </h6>

                        <br />
                        <br />

                        <p className="no-margin mt-30">
                          “The Electric light did not come from the continuous
                          improvement of candles. At the current pace of
                          technology, companies can no longer survive through
                          purely incremental improvement, you either choose to
                          disrupt or be disrupted, this merger exactly aims to
                          echo this vision and mission of our Cofounders”
                        </p>
                        <h6 className="quote-author mb-30">
                          Vijai Velu, CEO & Cofounder of Elfonze Technologies.
                        </h6>

                        <br />
                        <br />
                        <h2 className="mt-30">About Elfonze</h2>
                        <p>
                          Elfonze is a niche technology firm focused on
                          Enterprise & Digital Consulting, Connected Supply
                          Chain Services, Products Solutions and ESDM
                          acceleration. Headquartered in Bengaluru it has
                          offices across Bhubaneswar, Pune, Hyderabad in India
                          and in Denver, USA. With a strong workforce of 250+
                          talents it augments boutique consulting service for
                          65+ clients across the globe and works as a key MSP
                          partner for multiple products on the Supply Chain and
                          SaaS domains.
                        </p>

                        <div className="share-info">
                          {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                          <div className="tags">
                            <a href="#0">#ElfonzeTechnologies</a>,
                            <a href="#0">#Collaboration</a>,
                            <a href="#0">#Innovation</a>,
                            <a href="#0">#TechIndustryLeadership</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></div>
        </div>
      </section>
      <section
        className="call-action section-padding sub-bg bg-img"
        style={{ backgroundImage: 'url("/img/patrn.svg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-10">
                  <div className="sec-head  text-left">
                    {/* <h6 className="wow fadeIn" data-wow-delay=".5s">
                      Let's Start
                    </h6> */}
                    {/* <h3 className="wow color-font">
                      LET'S DESIGN THE WORLD WE WANT
                    </h3> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3 valign ab--col-flex">
              <p className="custom_color">For further information,<br/> Please Contact:</p>
              <h7>Sharat Kaul</h7>
              <p>Chief Visionary Officer</p>
              <Link
                href="mailto:sharat.dk@elfonze.com"
                className="butn bord curve wow fadeInUp"
                data-wow-delay=".5s"
                target="_blank"
              >
                <span>Contact</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>
      Merger of iAMPS & Hz AI-EMC Design Synthetics BU
      </title>
      <meta
        key="description"
        name="description"
        content="Elfonze Technologies accelerates innovation with the merger of iAMPS & Hz, reinforcing our commitment to excellence in Electronics System Design and Manufacturing (ESDM)."
      />
    </>
  );
};

export default BlogDetailsDark;
